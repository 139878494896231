import { memo } from 'react';
import classNames from 'classnames'
import _isNil from 'lodash/isNil';
import _isFunction from 'lodash/isFunction';
import useFastSpringStore from '../modules/FastSpringStore/useFastSpringStore';
import { removeFromCart } from '../modules/FastSpringStore/FastSpringStore.module';

export default memo(({ productId, children = 'Remove', className, onClick, ...props }) => {
	const [, dispatch] = useFastSpringStore()

	return (
		<button className={classNames('btn btn-sm btn-light', className)}
			onClick={() => {
				dispatch(
					removeFromCart(productId)
				)

				if (!_isNil(window)) {
					window.fastspring.builder.remove(productId)
				}

				_isFunction(onClick) && onClick()
			}}
			{...props}
		>
			{children}
		</button>
	)
})
