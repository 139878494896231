exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-html-js": () => import("./../../../src/pages/about-us.html.js" /* webpackChunkName: "component---src-pages-about-us-html-js" */),
  "component---src-pages-beta-download-html-js": () => import("./../../../src/pages/beta-download.html.js" /* webpackChunkName: "component---src-pages-beta-download-html-js" */),
  "component---src-pages-beta-signup-html-js": () => import("./../../../src/pages/beta-signup.html.js" /* webpackChunkName: "component---src-pages-beta-signup-html-js" */),
  "component---src-pages-black-friday-html-js": () => import("./../../../src/pages/black-friday.html.js" /* webpackChunkName: "component---src-pages-black-friday-html-js" */),
  "component---src-pages-black-friday-submit-html-js": () => import("./../../../src/pages/black-friday-submit.html.js" /* webpackChunkName: "component---src-pages-black-friday-submit-html-js" */),
  "component---src-pages-contact-response-html-js": () => import("./../../../src/pages/contact-response.html.js" /* webpackChunkName: "component---src-pages-contact-response-html-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-effects-air-creative-fx-collection-2-html-js": () => import("./../../../src/pages/effects/air-creative-fx-collection-2.html.js" /* webpackChunkName: "component---src-pages-effects-air-creative-fx-collection-2-html-js" */),
  "component---src-pages-effects-air-vocal-fx-collection-html-js": () => import("./../../../src/pages/effects/air-vocal-fx-collection.html.js" /* webpackChunkName: "component---src-pages-effects-air-vocal-fx-collection-html-js" */),
  "component---src-pages-effects-creative-fx-collection-plus-html-js": () => import("./../../../src/pages/effects/creative-fx-collection-plus.html.js" /* webpackChunkName: "component---src-pages-effects-creative-fx-collection-plus-html-js" */),
  "component---src-pages-effects-delaypro-html-js": () => import("./../../../src/pages/effects/delaypro.html.js" /* webpackChunkName: "component---src-pages-effects-delaypro-html-js" */),
  "component---src-pages-effects-ether-html-js": () => import("./../../../src/pages/effects/ether.html.js" /* webpackChunkName: "component---src-pages-effects-ether-html-js" */),
  "component---src-pages-effects-flex-beat-html-js": () => import("./../../../src/pages/effects/flex-beat.html.js" /* webpackChunkName: "component---src-pages-effects-flex-beat-html-js" */),
  "component---src-pages-effects-jura-chorus-html-js": () => import("./../../../src/pages/effects/jura-chorus.html.js" /* webpackChunkName: "component---src-pages-effects-jura-chorus-html-js" */),
  "component---src-pages-effects-soft-clipper-html-js": () => import("./../../../src/pages/effects/soft-clipper.html.js" /* webpackChunkName: "component---src-pages-effects-soft-clipper-html-js" */),
  "component---src-pages-effects-sprite-html-js": () => import("./../../../src/pages/effects/sprite.html.js" /* webpackChunkName: "component---src-pages-effects-sprite-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-html-js": () => import("./../../../src/pages/privacy.html.js" /* webpackChunkName: "component---src-pages-privacy-html-js" */),
  "component---src-pages-refundpolicy-js": () => import("./../../../src/pages/refundpolicy.js" /* webpackChunkName: "component---src-pages-refundpolicy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-subscriptions-html-js": () => import("./../../../src/pages/subscriptions.html.js" /* webpackChunkName: "component---src-pages-subscriptions-html-js" */),
  "component---src-pages-terms-of-use-html-js": () => import("./../../../src/pages/terms-of-use.html.js" /* webpackChunkName: "component---src-pages-terms-of-use-html-js" */),
  "component---src-pages-virtual-instruments-jura-html-js": () => import("./../../../src/pages/virtual-instruments/jura.html.js" /* webpackChunkName: "component---src-pages-virtual-instruments-jura-html-js" */),
  "component---src-pages-virtual-instruments-ring-the-alarm-html-js": () => import("./../../../src/pages/virtual-instruments/ring-the-alarm.html.js" /* webpackChunkName: "component---src-pages-virtual-instruments-ring-the-alarm-html-js" */),
  "component---src-pages-virtual-instruments-sub-factory-html-js": () => import("./../../../src/pages/virtual-instruments/sub-factory.html.js" /* webpackChunkName: "component---src-pages-virtual-instruments-sub-factory-html-js" */),
  "component---src-pages-virtual-instruments-timewarp-2600-html-js": () => import("./../../../src/pages/virtual-instruments/timewarp-2600.html.js" /* webpackChunkName: "component---src-pages-virtual-instruments-timewarp-2600-html-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-plugin-js": () => import("./../../../src/templates/plugin.js" /* webpackChunkName: "component---src-templates-plugin-js" */),
  "component---src-templates-product-2-js": () => import("./../../../src/templates/product2.js" /* webpackChunkName: "component---src-templates-product-2-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

