import { memo } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter,	faFacebook,	faYoutube,} from '@fortawesome/free-brands-svg-icons'
import BrandsSlider from 'components/BrandsSlider'
import Year from '../../../common/src/components/year'
import '../styles/footer.scss'
import Signup from './Signup'

export default memo(() => {
	return (
		<>
		<footer id="footer">
			<div className="container py-5">
				<div className="row">
					<div className="col-6 col-sm">
						<Link to="/">
							<img src="../icons/Air Logo.svg" alt="air logo" className='mb-3' />
						</Link>
						<ul className="d-flex p-0 m-0">
							<li>
								<a href="https://www.facebook.com/AIRmusictech/" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebook} style={{ width: 'auto', height: '28px' }} />
								</a>
							</li>
							<li className='mx-3'>
								<a href="https://twitter.com/airmusictech" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faTwitter} style={{ width: 'auto', height: '28px' }} />
								</a>
							</li>
							<li>
								<a href="https://www.youtube.com/AIRmusictech" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faYoutube} style={{ width: 'auto', height: '28px' }} />
								</a>
							</li>
						</ul>
					</div>

					<div className="col-6 col-sm">
						<h4>PRODUCTS</h4>
						<ul className="text-left pl-0">
							<li><Link to="/virtual-instruments.html">Virtual Instruments</Link></li>
							<li><Link to="/effects.html">Effects</Link></li>
							<li><Link to="/expansions.html">Expansions</Link></li>
							<li><Link to="/sound-packs.html">Sound Packs</Link></li>
							<li><Link to="/subscriptions.html">Subscriptions</Link></li>
						</ul>
					</div>

					<div className="col-6 col-sm">
						<h4>SUPPORT</h4>
						<ul className="text-left pl-0">
							<li><a href="http://support.airmusictech.com/support/home" target="_blank">Knowledge Base</a></li>
							<li>
								<a href="https://support.airmusictech.com/en/support/home" target="_blank" rel="noreferrer">
									Contact Support
								</a>
							</li>
							<li><Link to='/refundpolicy'>Refund Policy</Link></li>
							<li><Link to='/downloads'>Downloads</Link></li>
						</ul>
					</div>

					<div className="col-6 col-sm">
						<h4>COMPANY</h4>
						<ul className="text-left pl-0">
							<li><Link to="/about-us.html">About Us</Link></li>
							<li><a href="https://www.plugivery.com/about/dealers/" target="_blank">Dealers</a></li>
						</ul>
					</div>
					
					{/* <div className="col-12 col-lg-4 mt-lg-0 mt-3">
						<h4>STAY IN THE LOOP <img src="../icons/loop.svg" alt="loop" /></h4>
						<Signup />
					</div> */}
				</div>
			</div>
		</footer>
		<footer id="subfooter">
			<div className="container py-5">
				<div className="row text-center">
					<div className="col">
						<div id="inmusic-logos-scroller" className='mb-lg-5 mb-3'>
							<div className="inmusic-logos-scroller-title text-center">
								<StaticImage alt="InMusic logo" imgClassName="invert" placeholder="blurred" height={24} src="../images/brand-logos/logo-inmusic-wht.png" />
								<p className='border-bottom py-3'>Home of the world's premier music and audio technology brands</p>
							</div>
							<div className="">
								<BrandsSlider />
							</div>
						</div>
						
						<div className="d-flex flex-sm-nowrap flex-wrap justify-content-center mb-lg-4 mb-3">
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();

									if (typeof window !== 'undefined') {
										if (typeof window?.FreshworksWidget !== 'undefined') {
											window.FreshworksWidget('open');
										}
									}
								}}
							>
								Support
							</a>
							<a href="https://www.inmusicbrands.com/careers" target="_blank" rel="noreferrer">Careers</a>
							<Link to="/privacy.html" target="_blank">
								Privacy
							</Link>
							<Link to="/terms-of-use.html" target="_blank">
								Terms of Use
							</Link>
						</div>

						<p>© <Year /> inMusic Brands Inc. • All Rights Reserved.</p>
					</div>
				</div>
			</div>
		</footer>
		</>
	)
})
