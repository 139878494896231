import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const BrandSlide = ({ link, image }) => (
	<a href={link} target="_blank" >
		<GatsbyImage
			image={getImage(image)}
			loading="eager"
			alt=""
		/>
	</a>
)

const BrandSlider = () => {
	const { brands } = useStaticQuery(graphql`
      query {
          brands: allBrandsJson {
              nodes {
                  link,
                  image {
                      childImageSharp {
                          gatsbyImageData(height: 25, layout: FIXED, placeholder: TRACED_SVG)
                      }
                  }
              }
          }
      }
  `)

	return (
		<Slider
			className="inmusic-logos-scroller invert"
			infinite={true}
			centerMode={true}
			variableWidth={true}
			speed={300}
		>
			{
				brands.nodes.map((brand) => (
					<BrandSlide
						{...brand}
						key={brand.link}
					/>
				))
			}
		</Slider>
	)
}

export default BrandSlider
