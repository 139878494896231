import * as React from 'react'
import '../styles/signup.scss'

const Signup = ({ className }) => {
    return(
        <form id='signup' className={className} action="https://cloud.mailer.inmusicbrands.com/sighup-handler" method="POST">
			<input type="hidden" name="retURL" value="https://www.airmusictech.com/" />
			<input type="hidden" name="lead_source" value="Website" />
			<input type="hidden" name="MID" value="514000303" />
			<input id="email" maxlength="80" name="email" size="20" type="text" placeholder="Enter your email here" />
			<br/>
			<input type="submit" name="submit" value="SIGN UP" className='mt-3 btn-outline-yellow' />
		</form>
    )
}

export default Signup
