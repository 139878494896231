import { createContext } from "react"

// https://stackoverflow.com/questions/50502664/how-to-update-the-context-value-in-a-provider-from-the-consumer
// Define the shape of value={} on the contex
export const defaultCartValue = {
    showCart: false,
	setShowCart: (val) => {}
}

const CartContext = createContext(defaultCartValue)

export default CartContext
