import '../styles/cart.scss'
import { useContext, useEffect, useRef, useState } from 'react'
import CartContext from '../context/cart'
import useFastSpringStore from 'common/modules/FastSpringStore/useFastSpringStore'
import React from "react"
import RemoveButton from 'common/components/RemoveButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function Cart() {
    const [{ productsInCart, products, order }] = useFastSpringStore()
    const productsInCartWithData = products.filter(x => productsInCart.includes(x.pid))

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(Object.keys(order).length === 0)
    }, [order])

    function checkout() {
        if (typeof window !== 'undefined') window.fastspring.builder.checkout()
    }

    const { setShowCart } = useContext(CartContext)
    return (
        <div id="cart">
            <button onClick={() => setShowCart(false)}>
                <img src="../icons/x.svg" height={20} width={20} />
            </button>

            <div id="items" className='mb-5'>
                {
                    loading ?
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-muted fa-spin ml-auto mr-auto mt-5 mb-5 d-block"
                            size="2x"
                        />
                        :
                        productsInCartWithData.map(product => {
                            return (
                                <div className='d-flex mb-3 align-items-center' key={`product-in-cart-${product.pid}`}>
                                    <img src={product.image} height={48} width={48} style={{ borderRadius: 4 }} />
                                    <span className='ml-3'>{product.display}</span>
                                    <span className='ml-auto mr-3 text-right'>
                                        {product.priceTotal !== product.total && <p className='priceTotal m-0'>{product.priceTotal}</p>}                                        
                                        <p className='total m-0'>{product.total}</p>
                                    </span>
                                    <RemoveButton productId={product.pid} className='trash'>
                                        <img src='../icons/trash.svg' className='m-0' />
                                    </RemoveButton>
                                </div>
                            )
                        })
                }
            </div>

            <div id="checkout" className='mt-3'>
                <p><span>Subtotal: ({productsInCart.length} items)</span><span>{order.originalTotal}</span></p>
                <p><span>Discount:</span><span>-{order.discountTotal}</span></p>
                <p><span>Tax:</span><span>{order.tax}</span></p>
                <p className='my-5' style={{ fontWeight: 'bold' }}><span>Total:</span><span>{order.totalWithTax}</span></p>
                <button onClick={checkout} className='btn-block btn-lg'>CHECKOUT</button>
                <div>
                    <img src='../icons/lock.svg' />
                    &nbsp;
                    Secured By FastSpring
                </div>
            </div>
        </div>
    )
}
