import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons"
import CartButton from 'common/components/CartButton'
import useStickyNav from 'common/hooks/useStickyNav'
import '../styles/nav.scss'
import { useContext, useEffect, useRef } from "react"
import CartContext from "../context/cart"
import useFastSpringStore from 'common/modules/FastSpringStore/useFastSpringStore'

export default () => {
	const { isSticky, navRef, toggleNav, navVisible } = useStickyNav()
	const { setShowCart } = useContext(CartContext)
    const [{ productsInCart }] = useFastSpringStore()

	return (
		<div id="nav" className='sticky-top' ref={navRef}>
			<nav className="container navbar navbar-expand-md">
				<Link to="/" className="navbar-brand">
					<img src="../icons/Air Logo.svg" alt="AIR" />
				</Link>

				<button onClick={toggleNav} className="navbar-toggler" type="button" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<img src="../icons/toggler.svg" alt="logo" placeholder="blurred" layout="fixed" width={30} height={30} />
				</button>

				<div className={`collapse navbar-collapse ${navVisible ? 'show' : ''}`} id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<div className="dropdown">
								<button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
									PRODUCTS
								</button>
								<div className="dropdown-menu dropdown-menu-right p-0">
									<Link className="dropdown-item py-3 px-5" to="/virtual-instruments.html">VIRTUAL INSTRUMENTS</Link>
									<Link className="dropdown-item py-3 px-5" to="/effects.html">EFFECTS</Link>
									<Link className="dropdown-item py-3 px-5" to="/expansions.html">EXPANSIONS</Link>
									<Link className="dropdown-item py-3 px-5" to="/sound-packs.html">SOUND PACKS</Link>
									<Link className="dropdown-item py-3 px-5" to="/subscriptions.html">SUBSCRIPTIONS</Link>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<Link to="/about-us.html">ABOUT</Link>
						</li>
						<li className="nav-item">
							<a href="https://support.airmusictech.com/en/support/home" target="_blank" rel="noreferrer">
								SUPPORT
							</a>
						</li>
						
						{/* DESKTOP */}
						<li className="nav-item ml-5 d-none d-md-block position-relative">
							{
								productsInCart.length > 0 &&
								<span className="cartCount position-absolute">{productsInCart.length}</span>
							}
							<button onClick={() => setShowCart(true)} title="Cart" className='p-0 cartButton'>
								<img src="../icons/shopping-bag.svg" alt="shopping bag (cart)" />
							</button>
						</li>
						<li className="nav-item d-none d-md-block">
							<a href="https://airmusictech.onfastspring.com/account" title="Account">
								<img src="../icons/user.svg" alt="my account" />
							</a>
						</li>
						<li className="nav-item d-none d-md-block m-0">
							<Link to="/search" title="Search">
								<img src="../icons/search.svg" alt="search" />
							</Link>
						</li>

						{/* MOBILE */}
						<li className="nav-item d-flex justify-content-center d-md-none mb-3">
							<span className="position-relative">
								{
									productsInCart.length > 0 &&
									<span className="cartCount position-absolute">{productsInCart.length}</span>
								}
								<button onClick={() => setShowCart(true)} title="Cart" className='p-0 cartButton'>
									<img src="../icons/shopping-bag.svg" alt="shopping bag (cart)" />
								</button>
							</span>
							
							<a href="https://airmusictech.onfastspring.com/account" className="mx-3" title="Account">
								<img src="../icons/user.svg" alt="my account" />
							</a>
							<Link to="/search" title="Search">
								<img src="../icons/search.svg" alt="search" />
							</Link>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	)
}
